import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Member from "./Member"
import Team from "./Team"

export default function AboutTeam() {
  const architectureTeam = [
    {
      name: "Chris Sung",
      title: "Associate Architect",
      image: <StaticImage src="../../images/about-us/05.jpg" />,
      duties: [
        "Project leader",
        "Design",
        "Design development & documentation",
      ],
    },
    {
      name: "Jen Kim",
      title: "Architecture",
      image: <StaticImage src="../../images/about-us/06.jpg" />,
      duties: [
        "Project leader",
        "Design",
        "Design development & documentation",
      ],
    },
    {
      name: "Bill Keremelevski",
      title: "Architecture",
      image: <StaticImage src="../../images/about-us/09.jpg" />,
      duties: ["Design", "Design development & documentation"],
    },
    {
      name: "Mina Rez",
      title: "Architecture",
      image: <StaticImage src="../../images/about-us/10.jpg" />,
      duties: ["Architecture graphic", "Architectural design", "Documentation"],
    },
  ]
  const interiorTeam = [
    {
      name: "Amy Wuttke",
      title: "Interior Design",
      image: <StaticImage src="../../images/about-us/07.jpg" />,
      duties: [
        "Project leader",
        "Design development",
        "Material compliance",
        "ESD WELL",
      ],
    },
    {
      name: "Corrine Maluccio",
      title: "Interior Design",
      image: <StaticImage src="../../images/about-us/08.jpg" />,
      duties: ["Interior design vision", "FF and E", "ESD"],
    },
    {
      name: "Anu Desai",
      title: "Interior Design",
      image: <StaticImage src="../../images/about-us/11.jpg" />,
      duties: [
        "Interior design",
        "Design development",
        "Leader Revit",
        "Documentation",
      ],
    },
    {
      name: "Lachlan Winzer",
      title: "Interior Design",
      image: <StaticImage src="../../images/about-us/12.jpg" />,
      duties: ["Senior interior design", "Design and delivery"],
    },
    {
      name: "Fabiane Perez",
      title: "Interior Design",
      image: <StaticImage src="../../images/about-us/13.jpg" />,
      duties: ["Interior design", "Design development & documentation"],
    },
  ]

  return (
    <div className="mt-8 md:mt-16 flex flex-col items-center">
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-12 mb-12">
        <Member
          name={"Tony Mallucio"}
          title={"Director of Architecture"}
          image={<StaticImage src="../../images/about-us/03.jpg" />}
          duties={[
            "Lead client contact",
            "Concept director",
            "Design manager",
            "ISO risk management",
            "Operation sign-off on F & B",
            "Engineer life cycle assistance",
          ]}
        />
        <Member
          name={"Surachai Noppa"}
          title={"Director of Interior Design"}
          image={<StaticImage src="../../images/about-us/04.jpg" />}
          duties={[
            "Design direction",
            "Narrative design direction",
            "Design vision for Architecture & Interior Design",
            "FF and E",
          ]}
        />
      </div>
      <div className="flex flex-col sm:flex-row sm:space-x-12 items-center sm:items-start">
        <Team name="Architecture Team" members={architectureTeam} />
        <Team name="Interior Team" members={interiorTeam} />
      </div>
    </div>
  )
}
