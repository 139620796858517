import React from "react"

export default function Member({ image, duties, name, title, tileSize }) {
  return (
    <div className={`group ${tileSize ? tileSize : "sm:w-56"} flex flex-col`}>
      <div className="mb-2 sm:mb-0">{image}</div>
      <div>
        <div className="flex flex-col items-start">
          <h4 className="font-serif text-lg">{name}</h4>
          <p className="text-xs">{title}</p>
        </div>
        <ul className="flex flex-col items-start leading-4 mt-2 w-full lg:opacity-0 group-hover:opacity-100 ease-in-out duration-300">
          {duties.map((duty, index) => (
            <li
              key={index}
              className="text-2xs list-disc list-outside ml-2 text-left"
            >
              <p className="ml-4">{duty}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
