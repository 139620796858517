import React from "react"
import Member from "./Member"

export default function Team({ name, members }) {
  return (
    <div className="flex flex-col">
      <h3 className="playfair-title text-xl">{name}</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-8 gap-y-8 sm:gap-y-16">
        {members.map((member, index) => (
          <Member key={index} {...member} tileSize="sm:w-44" />
        ))}
      </div>
    </div>
  )
}
