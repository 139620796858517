import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import AboutTeam from "../components/about-us/AboutTeam"

export default function AboutUs({ data }) {
  // const projectNames = [
  //   "Queanbeyan Bowling Club",
  //   "Marconi Club Foyer",
  //   "Maitland Club",
  //   "Cronulla Sutherland Sharks Club Redevelopment",
  //   "Beecroft Bowling Club",
  //   "Cudgen Leagues Club",
  //   "Marconi Function Rooms Upgrade",
  //   "Norths Club",
  //   "Seagulls Leagues Club",
  //   "Barangaroo Pavilion",
  //   "Lane Cove Warehouse Development",
  //   "Eastern Valley Way Master Plan",
  //   "Tweeds Heads Master Plan",
  //   "Vincentia Retail",
  //   "Culvert Hotel",
  //   "Euston Motel Cabins",
  //   "Chester Hill Affordable Housing",
  //   "Chester Hill Apartments",
  //   "Sefton Affordable Housing",
  //   "Gymea Medium Density Housing",
  //   "35 Senior Living Apartments - Port Macquarie",
  //   "Marconi Independent Living",
  //   "St Ives Senior Living",
  //   "Touriandi Apartments - Dementia Wing (Aged Care)",
  //   "Tondara Apartments - Sutherland NSW",
  //   "Beverley Hills Residential",
  //   "Enmore Residential",
  //   "Erskineville Residential",
  //   "Five Dock Residential",
  //   "Kyle Bay Residential",
  //   "Marrickville Residential",
  //   "Sylvania Residential",
  //   "Woronora Residential",
  //   "Mona Vale Residential",
  //   "Manly Residential",
  //   "Killcare Residential",
  // ]
  // const details = data.strapiDetails

  return (
    <Layout
      Seo={{ title: "About Us" }}
      noHero
      content={
        <section className="bg-gray-200 flex flex-col justify-center items-center w-screen overflow-hidden">
          <div className="w-full lg:max-w-screen-lg pb-12">
            <div>
              <StaticImage
                src="../images/about-us/01.jpg"
                alt="A photo of wallpaper patterning inside the Club Marconi Foyer."
                className="h-[60vh]"
              />
              <p className="tiny-title my-4 text-right">
                A spatial detail from the recently completed Marconi Club Foyer
              </p>
            </div>
            <div className="flex flex-col items-center px-4 sm:px-6 md:px-8 lg:px-12 regular leading-loose text-sm space-y-6 lg:space-y-12">
              <div className="flex flex-col items-center">
                <h1 className="playfair-title text-3xl text-center">
                  DACCA Architecture
                </h1>
                <div className="paragraph-container">
                  <p>
                    Established in July 2018. We are a boutique, integrated,
                    architecture and interior design studio based in Sydney.
                  </p>
                  <p>
                    Our studio has over 40 years combined experience working on
                    similar projects, including CLUBS, hotels and retail
                    precincts.
                  </p>
                  <p>We design HOSPITALITY ARCHITECTURE</p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="playfair-title text-3xl">Our Approach</h1>
                <div className="paragraph-container">
                  <p>
                    Is always to explore the best possible design opportunities
                    to meet the brief.
                  </p>
                  <p>
                    With a focus on sustainability, cost efficiency and
                    innovation. We work closely with clients to deliver projects
                    which exceed initial expectations.
                  </p>
                  <p className="!mb-8">
                    As a boutique studio we have the luxury of a tight knit team
                    who foster communication and collaboration to push design
                    ideas and creatively approach problems.
                  </p>
                  <div className="overflow-hidden">
                    <StaticImage
                      src="../images/about-us/02.jpg"
                      alt="A concept image of a multi-residential development."
                      className="max-w-lg -translate-y-10asdf"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="playfair-title text-3xl">Our Services</h1>
                <div className="paragraph-container">
                  <p>
                    Architecture | Interior Architecture | Master Planning | FF
                    & E
                  </p>
                  <p>Strategic Planning | Lighting Design</p>
                  <p>
                    Concept Design | Authority Submission | Tender Documentation
                    Construction
                  </p>
                  <p>Documentation | Construction Phase</p>
                </div>
                <div className="mt-4 lg:mt-8 paragraph-container">
                  <p className="font-bold">Sectors</p>
                  <p>Clubs | Pubs | Hotels | Retail | Senior Living</p>
                  <p>
                    Residential | Affordable Housing | Multi Residential |
                    Industrial
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center text-center">
                <h1 className="playfair-title text-3xl">Our Team</h1>
                <div className="paragraph-container">
                  <p>
                    The team at DACCA Architecture is young and dynamic, yet
                    have the benefit of many years of industry experience across
                    multiple sectors. This industry experience equips us with
                    the understanding of a client's expectations and allows us
                    to push the vision and innovate, not only satisfying the
                    initial requirements around scope and budget but exceeding
                    expectations with creative design solutions.
                  </p>
                  <p>
                    We are nimble and scalable as we foster working
                    relationships with other independent designers who enjoy
                    jumping into our projects as required.
                  </p>
                </div>
                <AboutTeam />
                <p className="my-6 lg:my-12">
                  With our many years of experience in the industry, we have
                  delivered some amazing projects and won a number of awards.
                  Our delivery method is unique as we collaborate with key
                  experts in the sector to deliver our projects, achieving great
                  outcomes for the client and allowing us to provide a high
                  level of service.
                </p>
                <h1 className="playfair-title">Creating DACCA</h1>
                <div className="grid md:grid-cols-2 gap-4 max-w-md">
                  <div className="flex flex-col justify-end lg:items-end text-left lg:text-right">
                    <div className="tiny-title !normal-case text-xs text-right">
                      <p className="font-serif text-lg">Tony Maluccio</p>
                      <p className="text-base">DACCA Architecture</p>
                    </div>
                  </div>
                  <div className="aspect-[6/7] overflow-hidden">
                    <StaticImage
                      src="../images/about-us/14.jpg"
                      alt="A greyscale image of Tony Maluccio in the DACCA Group office."
                    />
                  </div>
                </div>
                <div className="my-6 lg:my-12 space-y-4 lg:space-y-8 text-xs leading-loose">
                  <p>
                    Having grown up in a building family, a deep respect for the
                    built form runs through his veins combined with a practical
                    knowledge of how buildings work. "I always knew I would work
                    in the building industry. My father was a builder, and from
                    a young age I loved nothing more than to go on site with my
                    father and help on the tools. At school I showed talent in
                    both technical drawing and woodwork and this led to the
                    study of Architecture Technology, followed by my Bachelor of
                    Architecture (Honours) at the University of NSW."
                  </p>
                  <p>
                    Practical experience combined with the study of both
                    technical and design architecture gives him the
                    understanding and technical knowledge of how to take a
                    building from design concept through to reality. "After a
                    background in the aged care, residential, retail and
                    hospitality sectors, I started my own registered
                    architectural company. At DACCA Architecture I am
                    responsible for the front-end design work and management of
                    execution, drawing upon my experience having lead projects
                    such as the award-winning 'The Quarter' in Darwin and many
                    varied hospitality projects."
                  </p>
                  <StaticImage
                    src="../images/about-us/15.png"
                    alt="A collage of architectural design concepts."
                    className=""
                  />
                  <p>
                    "One of the things I love most about the building industry
                    and environment is design and design management. I believe
                    that good design comes from a strong vision, and if you stay
                    true to that, the building will speak for itself." He prides
                    himself on going above and beyond what a normal architect
                    would do. He sees himself as a design consultant and treats
                    client projects as if they were his own. He understands how
                    to get things built and is strong on time management. "I
                    love the collaboration that comes from teamwork, taking
                    something from an initial idea through to the final,
                    functioning built form."
                  </p>
                </div>
              </div>
              {/* <div className="paragraph-container">
                <h1 className="playfair-title text-3xl">Our Projects</h1>
                <div className="columns-2 sm:columns-3 lg:columns-4 w-full">
                  {projectNames.map(project => (
                    <p className="text-left mb-3 text-xs leading">{project}</p>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </section>
      }
    />
  )
}

export const query = graphql`
  query details {
    strapiDetails {
      tonyEmail
      tonyNumber
    }
  }
`
